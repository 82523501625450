import React from 'react';
import { graphql } from 'gatsby';
// import get from 'lodash/get';

import Layout from '../components/layout';
// import Hero from '../components/index/hero';
import ArticlePreview from '../components/article-preview';

const RootIndex = ({ data, location }) => {

    const posts = data.allContentfulArticle.nodes;
    // const [banner] = get(this, 'props.data.allContentfulBanner.nodes');

    return (
        <Layout location={location}>
            {/* <Hero
                image={banner.heroImage.gatsbyImageData}
                title={banner.name}
                content={banner.description}
                link={banner.link}
            /> */}
            <ArticlePreview posts={posts} />
        </Layout>
    );

}

export default React.memo(RootIndex);

export const pageQuery = graphql`
    query HomeQuery {
        allContentfulArticle(
            limit: 36
            sort: { fields: publishDate, order: DESC }
        ) {
            nodes {
                title
                slug
                publishDate(formatString: "MMMM Do, YYYY")
                tags
                heroImage {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        height: 212
                        width: 424
                    )
                }
                description {
                    raw
                }
            }
        }
    }
`

// allContentfulBanner(
//     limit: 1
//     sort: { fields: [publishDate], order: DESC }
// ) {
//   nodes {
//     name
//     description
//     publishDate
//     link
//     heroImage: image {
//       gatsbyImageData(
//         layout: CONSTRAINED
//         placeholder: BLURRED
//         width: 1275
//       )
//     }
//   }
// }